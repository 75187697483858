<template>
  <div class="team-section">
    <div class="team-section__title">{{ title }}</div>
    <div class="row">
      <div class="col-md-6 col-lg-3" v-for="employe in employees" :key="employe.id + employe.fullName">
        <div class="employe-card">
          <div class="employe-card__bg" @click="selectAgent(employe.id)"></div>
          <img
            :src="agentPhoto(employe.photo)"
            class="employe-card__avatar"
            alt=""
          />
          <div class="employe-card__title">{{ employe.fullName }}</div>
          <div class="employe-card__position">{{ employe.position }}</div>
          <hr class="employe-card__delimiter" />
          <div class="d-flex justify-content-center">
            <a :href="'tel:' + employe.phone" class="btn btn-control me-1">
              <img src="@/assets/icons/line-icon call line.svg" alt="" />
            </a>
            <a :href="'mailto:' + employe.email" class="btn btn-control ms-1">
              <img src="@/assets/icons/icon email line.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <profile-modal
        v-if="ui.profileModal"
        :id="selectedAgent"
        @close="ui.profileModal = false"
      />
    </transition>
  </div>
</template>

<script>
export default {
  components: {
    ProfileModal: () => import("../../loans/modals/ProfileModal.vue"),
  },
  name: "TeamSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    employees: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ui: {
        profileModal: false,
      },
      selectedAgent: null,
    };
  },
  methods: {
    selectAgent(agentId) {
      this.ui.profileModal = true;
      this.selectedAgent = agentId;
    },
  },
};
</script>

<style lang="scss" scoped>
.team-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 24.5px;
  &__title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 20px;
  }
}
.employe-card {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 30px 37px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all .3s;
  text-align: center;
  &:hover {
    border: 1px solid black;
  }
  .btn {
    position: relative;
    z-index: 99;
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
        top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__avatar {
    height: 100px;
    width: 100px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    margin-bottom: 18px;
    object-fit: cover;
  }

  &__title {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__position {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__delimiter {
    width: 100%;
    background: #cccccc;
    opacity: 1;
    color: #cccccc;
  }
}
</style>
